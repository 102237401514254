<template>
  <vx-card class="mt-base">
    <div id="productCategoryTable" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="productData"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        >
        <!-- search -->
        <!-- @search="handleSearch" -->
        <div slot="header" class="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full mb-5">
          <p class="text-xl font-semibold w-full">Product List</p>
          <div class="flex flex-col sm:flex-row justify-end sm:flex-grow items-center gap-4 w-full">
            <vs-input
              class="w-full sm:w-auto"
              icon="search"
              placeholder="Search product"
              v-model="dataTableParams.search"
            />
            <vs-button
              class="w-full sm:w-auto"
              @click="addProductPopup = true"
            >Add Product</vs-button>
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="w-full sm:w-24"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Sort</vs-th>
          <vs-th>Product Name</vs-th>
          <vs-th>Supplier's Name</vs-th>
          <vs-th>Cost</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <draggable :list="data" @change="updateOrderNumber(data)" tag="tbody" :disabled="isDragDisabled">
            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <td>
                  <div class="flex items-center justify-start gap-2">
                    <span v-if="!isDragDisabled" class="cursor-pointer"><vs-icon icon="reorder" size="small" class="text-primary2"></vs-icon></span>
                    <vs-input
                      ref="orderInput"
                      class="no-spin w-auto"
                      color="success"
                      v-model="tr.productDetails.orderId"
                      min="1"
                      :max="productData.length"
                      type="number"
                      @input="handleFieldSort(tr, indextr)"
                    />
                  </div>
                </td>
                <td :data="data[indextr].productDetails.name">
                  <router-link
                    exact
                    class="text-black"
                    :to="handleRedirectToProduct(tr.productDetails._id)"
                    target="target"
                  >
                    {{
                      data[indextr].productDetails.name
                    }}
                  </router-link>
                </td>
                <td :data="data[indextr].productDetails.supplierDetails[0].name">
                  {{
                  data[indextr].productDetails.supplierDetails[0].name
                  }}
                </td>
                <td :data="data[indextr].productDetails.productType">
                  {{
                  data[indextr].productDetails.price ? `$${(data[indextr].productDetails.price / 100).toFixed(2)}` : null
                  }}
                </td>
                <td :data="data[indextr].productDetails._id">
                  <vx-tooltip text="Remove Product" position="left">
                    <vs-button
                      type="border"
                      size="small"
                      color="danger"
                      @click="openDeletePopup(data[indextr].productDetails._id)"
                      icon-pack="feather"
                      icon="icon-trash"
                      class="mt-1"
                    ></vs-button>
                  </vx-tooltip>
                </td>
                <vs-popup :active.sync="popUpDelete" title="Remove Product from Brand">
                  <p align="center">Are you sure you want to remove this Product from Brand?</p>
                  <br />
                  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                    <vs-col vs-type="flex" vs-align="left" vs-w="6">
                      <div class="w-full m-5" align="right">
                        <vs-button
                          size="lg"
                          color="primary"
                          @click="deleteDetailHandler(popupData.id)"
                        >Yes</vs-button>
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="left" vs-w="6">
                      <div class="w-full m-5">
                        <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                      </div>
                    </vs-col>
                  </vs-row>
                </vs-popup>
              </tr>
          </draggable>
        </template>
      </vs-table>
      <vs-popup id="addProductPopup" :active.sync="addProductPopup" title="Add Product" @close="handleCloseStorePopup">
        <div class="relative">
          <vs-select
            placeholder="Select product"
            label="Products"
            multiple
            id='productSelect'
            autocomplete
            v-model="productIds"
            class="w-auto m-2"
            @input-change="customFilter"
            @focus="onFocus"
          >
            <vs-select-item
              :key="index"
              :value="item._id"
              :text="item.name"
              v-for="(item, index) in products"
            />
          </vs-select>
          <vs-icon
            v-if="productIds.length"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="productIds = []">
          </vs-icon>
        </div>
        <vs-button
          @click="addProduct"
          color="primary"
          id='confirmRequest'
          class="m-2"
          type="border"
          >Add</vs-button
        >
      </vs-popup>
    </div>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import draggable from "vuedraggable";
import _ from "lodash";

export default {
  name: 'ProductListByBrand',
  components: {
    vSelect,
    draggable,
  },
  props:{
    brandId: {
      type: String
    }
  },
  data() {
    return {
      id: this.brandId,
      totalDocs: 0,
      page: 1,
      noDataText: 'No products found',
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      serverResponded: false,
      awaitingSearch: null,
      productData: '',
      popUpDelete: false,
      popupData: {
        id: ''
      },
      isDragDisabled: false,
      addProductPopup: false,
      products: [],
      productIds: [],
      storeProductsParams: {
        search: "",
      },
    };
  },
  methods: {
    ...mapActions("brand", [
      "getBrandProductList",
      "removeProductFromBrand"
    ]),
    ...mapActions("admin", ["sortBrandProductList"]),
    ...mapActions("storeProducts", [
      "fetchProducts",
      "addProductsToBrand",
      "fetchProductsNotInBrand"
    ]),
    handleCloseStorePopup() {
      this.addProductPopup = false;
      this.productIds = [];
      this.storeProductsParams.search = "";
      this.getStoreProducts();
    },
    customFilter: _.debounce(function (data) {
        this.storeProductsParams.search = data.target.value;
        this.products = []
        this.page = 1
        this.getStoreProducts();
    }, 500),
    async addProduct() {
      try {
        this.$vs.loading({
          text: "Adding products...",
          color: "#3dc9b3",
          background: "white",
          container: "#addProductPopup",
          scale: 0.6,
        });
        console.log(this.productIds);
        const payload = {
          brandId: this.id,
          productIds: this.productIds
        }
        const res = await this.addProductsToBrand(payload);
        if (res.status === 200) {
          this.addProductPopup = false;
          this.fetchProductListByBrand();
        }
        this.$vs.loading.close("#addProductPopup > .con-vs-loading");
      } catch (error) {
        this.$vs.loading.close("#addProductPopup > .con-vs-loading");
        console.error("ERR: ", error);
      }
    },
    async getStoreProducts() {
      try {
        // const payload = {
        //   search: this.storeProductsParams.search,
        //   brandId: this.id,
        // }
        const payload = {
          search: this.storeProductsParams.search,
          brandId: this.id,
          page: this.page,
          limit: 100
        }
        const res = await this.fetchProductsNotInBrand(payload);
        if (res.status === 200) {
          // this.products = res.data.data;
          this.products = [...this.products ,...res.data.data.docs];
          this.totalDocs = res.data.data.pagination.total
          this.loading = false
        }
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    handleRedirectToProduct(id) {
      let editRoute = `/super-admin/store/products/${id}/edit`;
      if (this.loggedInUserType === 'admin') editRoute = `/admin/store/products/${id}/edit`;
      return editRoute;
    },
    handleFieldSort:_.debounce(async function (data, index) {
      const newOrder = parseInt(data.productDetails.orderId);
      if (!isNaN(newOrder)) {
        data.productDetails.orderId = newOrder;

        const inputElement = this.$refs.orderInput[index].$el.querySelector("input");
        if (inputElement) {
          inputElement.blur();
        }

        const payload = {
          brandId: data._id,
          productId: data.productDetails._id,
          orderId: data.productDetails.orderId
        }
        await this.updateOrderNumber([payload]);
      }
    }, 1000),
    async updateOrderNumber(props) {
      if (props) {
        this.$vs.loading({
          text: "Sorting...",
          color: "#3dc9b3",
          background: "white",
          container: "#productCategoryTable",
          scale: 0.6,
        });
        const products = props.length === 1 ? props : props.map((item, index) => {
          return {
            brandId: item._id,
            productId: item.productDetails._id,
            orderId: parseInt(index+1),
          }
        });
        const payload = {
          brandId: this.id,
          products
        }
        const res = await this.sortBrandProductList(payload);
        this.fetchProductListByBrand();
        if (res.status === 200) {
          this.$vs.notify({
            title: "Success",
            text: "Product Order Updated",
            color: "success",
          });
        }
        this.$vs.loading.close("#productCategoryTable > .con-vs-loading");
      }
    },
    sort(arr) {
      return arr.sort((a, b) => {
        if (a.productDetails.orderId === null) return 1;
        if (b.productDetails.orderId === null) return -1;
        return a.sort - b.sort;
      });
    },
    async fetchProductListByBrand() {
      try {
        const info = {
          dataTableParams: this.dataTableParams,
          id: this.id
        };
        const res = await this.getBrandProductList(info);
        if (res.status === 200){
          this.productData = this.sort(res.data.data); 
        }
      } catch (error) {
        console.error("ERR: ", error);
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      if (searching) {
        this.isDragDisabled = true;
      } else {
        this.isDragDisabled = false;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.$refs.table.currentx = 1;
        this.fetchProductListByBrand();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchProductListByBrand();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.$refs.table.currentx = 1;
      this.fetchProductListByBrand();
    },
    openDeletePopup(id) {
      this.popupData.id = id;
      this.popUpDelete = true;
    },
    async deleteDetailHandler(id) {
      await this.removeProductFromBrand(id)
        .then(res => {
          this.popUpDelete=false;
          this.fetchProductListByBrand();
          this.$vs.notify({
            title: "Success",
            text: "Product Removed successfully.",
            color: "success"
          });
        })
        .catch(err => {
          this.popUpDelete = false;
          this.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: "danger"
          });
        })
    },
 
    async loadMore() {
     if(this.hasNextPage){

      this.page += 1;
      await this.getStoreProducts();

     }
    },

    onScroll: _.debounce(async function (event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight - 30) {
       await this.loadMore();
      }
    }, 200),

    onFocus(){
      //Adding event listener when vs-select input is focus
      const dropdown = document.body.querySelector('.vs-select--options ul');
      dropdown.addEventListener('scroll', this.onScroll);
    }

  },
  watch: {
    "dataTableParams.search": function(newVal, oldVal) {
      this.handleSearch(newVal);
    },

    "addProductPopup": function(newVal, oldVal) {
      if(newVal){
        //Prevent parent to scroll while vs-select is active/open
        document.body.style.overflow = 'hidden';
      }else{
        //Put back parent scroll 
        document.body.style.overflow = 'auto';

        //Remove scroll event in vs-select
        const dropdown = document.body.querySelector('.vs-select--options ul');
        dropdown.removeEventListener('scroll', this.onScroll);
      }
    },
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    
    hasNextPage() {
      return this.products.length < this.totalDocs
    },
  },
  async created() {
    this.id = this.brandId;
    this.dataTableParams.brandId = this.brandId;
    await this.fetchProductListByBrand();
    await this.getStoreProducts();
  },
};
</script>

<style>
.no-spin input[type="number"]::-webkit-inner-spin-button,
.no-spin input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.removeSelectedFilters{
  position: absolute;
  right: 28px;
  bottom: 12px;
  cursor: pointer;
}
</style>
